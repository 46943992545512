<script>
import appConfig from "@/app.config";

export default {
  page: {
    title: "404 Error Page",
    meta: [{ name: "description", content: appConfig.description }]
  }
}
</script>
<template>
  <div class="account-pages my-5 pt-5" style="overflow: hidden;">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <h4 class="display-2 fw-medium">
              <!-- 4<i class="bx bx-buoy bx-spin text-primary display-3"></i>4 -->
            <img src="@/assets/images/404.png" alt class="img-fluid" />
            </h4>
            <h4 class="text-uppercase">MAAF, FITUR PEMBAYARAN<br>SEDANG TIDAK AKTIF</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

